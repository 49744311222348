import React from "react";
import { graphql } from 'gatsby'
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import SEO from "../components/seo"

const AreaGuideLanding = loadable(() => import("../components/AreaGuideLanding/AreaGuideLanding"));

const AreaGuides = (props) => {

    const items = props.data.allWpPost.nodes
    return (
        <Layout>
            <div className="layout-padding-top"></div>
            <div className="inner-wrapper">
                <Container>
                   <AreaGuideLanding data={items} />
                </Container>
            </div>

        </Layout>
    )
}


// export const Head = ({ data }) => {
//   const PageData = data?.strapiPage
//   return (
//     <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
//   )
// }
export default AreaGuides


export const query = graphql`
  query {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: "area-guide"}}}}, status: {eq: "publish"}}, sort: {order: DESC, fields: date}) {
      nodes {
        uri
        title
        status
        slug
        categories {
          nodes {
            name
            slug
            parentId
          }
        }
        content
        featuredImage {
            node {
              id
              sourceUrl
            }
        }
      }
    }
  }
`